export const container = () => ({
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginTop: '0.5rem',
    marginBottom: '2rem',
})

export const icons = () => ({
    marginLeft: 'auto',
})

export const logo = ({ theme }) => ({
    fontSize: '2.25rem',
    color: theme.colors.text,
    textDecoration: 'none',
    transition: 'color 0.25s',

    ':hover': {
        color: theme.colors.black,
    },
})

export const icon = ({ theme }) => ({
    padding: '0.25rem',
    color: theme.colors.text,
    transition: 'color 0.25s',

    ':hover': {
        color: theme.colors.black,
    },

    '& svg': {
        width: '1.5rem',
        height: 'auto',
    },
})
