export const container = ({ theme, main }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 0,
    width: main ? theme.metrics.mainMenuWidth : theme.metrics.menuWidth,

    '& a': {
        textDecoration: 'none',

        ':hover': {
            textDecoration: 'underline',
        },
    },
})

export const item = ({ theme, page, id }) => ({
    color: theme.colors.text,
    marginBottom: '.75rem',
    fontWeight: page === id && 400,
})
