import { React, PropTypes } from '../../dependencies'
import { Link } from 'gatsby'
import { FelaComponent } from 'react-fela'

const Menu = ({ styles, rules, page, items }) => (
    <nav className={styles.container}>
        {items.map(item => (
            <FelaComponent key={item.link} id={item.link} style={rules.item} page={page}>
                {({ className }) => (
                    <Link to={`/${item.link}`} className={className}>
                        {item.title}
                    </Link>
                )}
            </FelaComponent>
        ))}
    </nav>
)

Menu.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
        }),
    ).isRequired,
}

export default Menu
