import { Link } from 'gatsby'
import { React } from '../../dependencies'

const Header = ({ styles, siteTitle }) => (
    <header className={styles.container}>
        <Link to="/" className={styles.logo}>
            <h1>{siteTitle}</h1>
        </Link>
    </header>
)

export default Header
