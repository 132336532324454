import { graphql, useStaticQuery } from 'gatsby'
import { PropTypes, React } from '../../dependencies'
import Header from '../Header'
import Menu from '../Menu'

const navItems = [
    {
        title: 'Kontakt',
        link: 'kontakt',
    },
]

const Layout = ({ styles, children, page }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <div className={styles.container}>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div className={styles.layout}>
                <aside className={styles.aside}>
                    <Menu main items={navItems} page={page} />
                </aside>
                <main className={styles.main}>{children}</main>
            </div>
            <footer className={styles.footer}>
                Created by <a href="mailto:jiri@zdvomka.cz">Jiří Zdvomka</a>
            </footer>
        </div>
    )
}

Layout.propTypes = {
    center: PropTypes.bool,
}

Layout.defaultProps = {
    center: false,
}

export default Layout
