export const container = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    width: '100%',
    maxWidth: theme.metrics.containerWidth,
    minHeight: '100vh',
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: 0,
})

export const layout = () => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',

    '@media(min-width: 45rem)': {
        flexDirection: 'row',
    },
})

export const aside = () => ({
    marginBottom: '1.5rem',

    '@media(min-width: 45rem)': {
        marginRight: '4rem',
    },
})

export const main = ({ center }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: center ? 'center' : 'flex-start',
})

export const footer = ({ theme }) => ({
    flexShring: 0,
    textAlign: 'center',
    fontSize: '.85rem',
    marginTop: '2rem',
    color: theme.colors.link,
})
